import React from "react"

import SEO from "../components/seo"
import LandingPage from "../components/landing-page";
import FixedImage from "../components/fixed-image";

const NotFoundPage = () => (
    <LandingPage>
        <SEO title="Error 404" />
        <FixedImage alt="sitelabel - developer tools" filename="temporary-logo.png" />
    </LandingPage>
)

export default NotFoundPage
